        
<template>
    <highcharts class="highcharts-class" :options="chartOptions" ref="highcharts"></highcharts>
    <p class="gaugePercent">{{ this.percent }}%</p>
</template>

<script>

  export default {
    props: ['p','h'],
    watch: {
      p: {
        handler() {
          this.percent = this.p;
          this.updatePercent();
        },
        deep:true,
      },
      h: {
        handler() {
          //this.height = this.h;
          //this.updateHeight();
        },
        deep:true,
      },
    },
    methods: {
      updatePercent() {
        this.chartOptions.series[0].data[0].y = this.percent;
      },
      updateHeight() {
        console.log("Gauge Height" + this.height);
        //this.chartOptions.chart.height = this.height;
      }
    },
    mounted() {
        // console.log("Gauge Height" + this.h);
        //this.height = this.h;
    },
    data() { 
      return {
        percent:0,
        height:null,
        chartOptions: {
          chart: {
            type: 'solidgauge',
            backgroundColor: 'rgba(0,0,0,0)',
            height:null,
            width:null,
            animation:true,
          },
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          pane: {
            startAngle: 0,
            endAngle: 360,
            background: [{ 
                outerRadius: '95%',
                innerRadius: '75%',
                backgroundColor:'#80808077',
                borderWidth: 0
            },]
          },
          plotOptions: {
            solidgauge: 
            {
              dataLabels: {
                enabled: false
              },
              linecap: 'round',
              stickyTracking: false,
              rounded: true,
              animation: true,
            }
          },
          yAxis: 
          {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: [],
          },
          series: [{
            name: 'Percent Complete',
            data: [{
              color: 'green',
              radius: '95%',
              innerRadius: '75%',
              y: 0,
            }],
          }],
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
*{
  -webkit-transition: all 0ms ease !important;
  transition: all 0ms ease!important;
}
.gaugePercent {
  display: block;
  position: absolute;
  top: calc(100% - 1.5em);
  bottom: 0;
  left:0;
  right:0;
  height:100%;
  margin: auto;
  font-weight:bold;
  text-align:center;
}
.highcharts-class {
  height: 50px;
  width: 50px;
}

#progressBar{
  display: none;
}
#objUrlAttachments{
  display: none;
}
</style>